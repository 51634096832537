<template>
  <main>
    <section id="grocery_success">
      <div class="w1300">

        <div class="success_info txt-center" v-if="paymentSuccess === true">

          <!-- 選擇信用卡繳費時顯示 -->
          <p>
            <template v-if="$cookies.isKey('applyPointHost')">
              {{ $cookies.get('applyPointHost') }} 您好，<br><br>
            </template>
            <span class="txt-bold">您已繳費成功，</span><br><br>
            請至「積分申請查詢」查看結果。
          </p>

          <!-- 選擇非信用卡繳費時顯示 -->
          <!-- <p>王小明 您好，<br><br>您的繳費資料如下，<br><br>請於繳費後返回我的課程查看您的購買課程。</p> -->

          <!-- 選擇ATM繳費時顯示下方付費資訊區塊 -->
          <!-- <div class="success_detail txt-left">
            <p class="success_detail_title h6 txt-bold">請透過ATM轉帳至以下銀行帳號以完成繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>繳費銀行代碼</li>
                <li>繳費帳號</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>822</li>
                <li>9103522175887271</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div> -->

          <!-- 選擇超商繳費時顯示下方付費資訊區塊 -->
          <!-- <div class="success_detail txt-left">
            <p class="success_detail_title h6 txt-bold">請至7-11/全家/萊爾富/OK超商 多媒體機台列印繳費單繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>超商繳費代碼</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>GW130412257496</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div> -->

          <router-link to="/register/point/search" class="btn">返回查詢</router-link>
        </div>

        <div class="success_info txt-center" v-else-if="paymentSuccess === false">

          <p>
            <template v-if="$cookies.isKey('realname')">
              {{ $cookies.get('realname') }} 您好，<br><br>
            </template>
            <span class="txt-bold">由於繳費失敗，</span><br><br>
            請至「積分申請查詢」重新繳費。
          </p>

          <router-link to="/register/point/search" class="btn">返回查詢</router-link>
        </div>

        <div class="success_info txt-center" v-else>

          <p>
            查無訊息，請至「積分申請查詢」確認繳費項目。
          </p>

          <router-link to="/register/point/search" class="btn">返回查詢</router-link>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex';
// import { eventsApplyView, orderPay } from '@/lib/http';
import { paymentInfo } from '@/lib/http';
import _ from 'lodash';

export default {
  name: 'RegisterPointSearchSuccess',
  data() {
    return {
      th1: '費用名稱',
      th2: '收據抬頭',
      th3: '金額',
      applyID: '',
      applyInfo: {},
      contact_info: {
        tel: '0988-206590',
        email: 'hmca.tw@gmail.com',
      },
      paymentErrorMsg: '',
      resHtml: '',
      formData: {
        order_uuid: '',
        pay_id: '',
        detail: [],
        return_url: '',
      },
      paymentSuccess: null,
    };
  },
  created() {
    if (this.$route.query && this.$route.query.uuid) {
      paymentInfo({ uuid: this.$route.query.uuid }).then((result) => {
        this.paymentSuccess = _.get(result, 'data.result.completed', null);
      });
    } else {
      this.openModal({
        url: '/register/point/search',
        message: '請返回積分申請查詢頁選擇欲繳費項目',
      });
    }
  },
  computed: {
    applyHost() {
      return _.get(this.applyInfo, 'apply_host', '');
    },
    applyTopic() {
      return _.get(this.applyInfo, 'name', '');
    },
    applyStart() {
      return _.get(this.applyInfo, 'start_date', '');
    },
    applyEnd() {
      return _.get(this.applyInfo, 'end_date', '');
    },
    applyHour() {
      return _.get(this.applyInfo, 'hours');
    },
    applyOrders() {
      return _.get(this.applyInfo, 'order.order_details', []);
    },
    applyAmount() {
      return _.get(this.applyInfo, 'order.total', '');
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
  },
};
</script>
